import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import SetClient from "../components/setClient/SetClient";
import { useHistory } from "react-router-dom";

const Input = styled("input")({
  display: "none",
});

import DocumentUpload from "../components/documentUpload/documentUpload";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import callAPI from "../utils/callAPI";
import { NoAccess } from "../utils/constants";
import BasicPopover from "../components/popOver/BasicPopover";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Home({ isClicked, client, setClient }) {
  const [isFileProcessing, setIsFileProcessing] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(isFileProcessing);
  const popOverRef = React.useRef(null);

  const history = useHistory();
  const [accessSet, setAccessSet] = useState();
  const [switchValue, setSwitchValue] = useState(false);
  const [files, setFiles] = useState([]);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isProcessing, setIsprocessing] = useState(false);
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);

  const [documentProcessResponse, setDocumentProcessResponse] = useState({});

  const [openDialog, setOpenDialog] = useState(false);
  const handleClose = () => {
    setOpenDialog(false);
  };

  const handlePopOverClick = () => {
    setAnchorEl(popOverRef.current);
  };

  const handlePopOverClose = () => {
    setAnchorEl(null);
  };

  const handleSwitchChange = (event) => {
    setSwitchValue(!switchValue);
  };
  const handleSubmit = () => {
    setSubmitButtonLoading(true);
    callAPI("GET", "check_files_status", "", client).then((res) => {
      const fileProcessing = res.isfileprocessing;
      // isProcessing shows page loading
      // isFileProcessing shows previous file is under process
      if (fileProcessing) {
        setSubmitButtonLoading(false);
        setIsFileProcessing(true);
        handlePopOverClick();
      } else {
        setSubmitButtonLoading(false);
        setIsprocessing(true);
        let dataType;
        if (switchValue) {
          dataType = "historic";
        } else {
          dataType = "live";
        }
        const formData = new FormData();
        formData.append("submit", true);
        formData.append("dataType", dataType);
        formData.append("file_id", documentProcessResponse.file_id);
        callAPI("POST", `submit/`, formData, client)
          .then((res) => {
            history.push("/downloadexcel");
            setIsprocessing(false);
          })
          .catch((error) => {
            throwError(new Error(error));
          });
        history.push("/downloadexcel");
      }
    });
  };

  const navigateToDownloadPage = () => {
    history.push("/downloadexcel");
  };

  useEffect(() => {
    if (accessSet && accessSet.indexOf("uploadexcel") == -1) {
      history.push(NoAccess);
    }
  }, [accessSet]);

  useEffect(() => {
    callAPI("GET", "user_permissions", "", client).then((res) => {
      setAccessSet(res.permissions);
    });
  }, [client]);
  return (
    <section className="home">
      {isProcessing ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            width: "80vw",
            height: "50vh",
          }}
        >
          <CircularProgress size="60px" style={{ color: "#305697" }} />
        </div>
      ) : (
        <>
          <div
            style={{
              marginLeft: isClicked ? "10vw" : "",
              transitionDuration: "0.5s",

              // marginTop: isClicked ? "-8vh" : "",
            }}
          >
            <div className="home__content mt-5 d-flex  justify-content-between align-items-start">
              <div>
                <p>
                  Select the document to be processed and then click submit to
                  upload the document
                </p>
              </div>
              <div className="d-flex justify-content-center align-items-start h-100">
                <Button
                  size="large"
                  className="text-light"
                  variant="contained"
                  style={{ backgroundColor: "#305697" }}
                  onClick={navigateToDownloadPage}
                >
                  All Documents
                </Button>
              </div>
            </div>
            <div style={{ display: "inline-block" }}>
              <Grid
                component="label"
                className="mt-2"
                container
                alignItems="center"
                spacing={2}
              >
                <Grid item>Live</Grid>
                <Grid item>
                  <Switch
                    disabled={files.length >= 1}
                    checked={switchValue}
                    onClick={handleSwitchChange}
                    size="large"
                  />
                </Grid>
                <Grid item>Historic</Grid>
              </Grid>
            </div>
            <DocumentUpload
              files={files}
              setFiles={setFiles}
              switchValue={switchValue}
              setIsSuccess={setIsSuccess}
              setOpenDialog={setOpenDialog}
              setIsprocessing={setIsprocessing}
              setDocumentProcessResponse={setDocumentProcessResponse}
              client={client}
            />
            {Object.keys(documentProcessResponse).length ? (
              <>
                {isSuccess ? (
                  <Dialog
                    open={openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    // onClose={handleClose}
                  >
                    <DialogTitle>SUCCESS</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        <div style={{ display: switchValue ? "none" : "" }}>
                          <p className="mt-4">
                            Total Rows: {documentProcessResponse.totalRows}
                          </p>
                          <p>
                            Total Claims: {documentProcessResponse.totalClaims}
                          </p>
                        </div>
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                  </Dialog>
                ) : (
                  <Dialog
                    open={openDialog}
                    TransitionComponent={Transition}
                    keepMounted
                    // onClose={handleClose}
                  >
                    <DialogTitle>ERROR</DialogTitle>
                    <DialogContent>
                      <DialogContentText>
                        Please fix the following issues before submitting.
                        {documentProcessResponse.errors.map((error, index) => {
                          index++;
                          return (
                            <p className={index === 1 ? "mt-4" : ""}>
                              {index}. {error}
                            </p>
                          );
                        })}
                      </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleClose}>Ok</Button>
                    </DialogActions>
                  </Dialog>
                )}
              </>
            ) : (
              ""
            )}

            <div className="d-flex justify-content-center">
              <BasicPopover
                files={files}
                isSuccess={isSuccess}
                handleSubmit={handleSubmit}
                isFileProcessing={isFileProcessing}
                handlePopOverClose={handlePopOverClose}
                anchorEl={anchorEl}
                popOverRef={popOverRef}
                submitButtonLoading={submitButtonLoading}
              />
              {/* <Button
                size="large"
                className="text-light"
                variant="contained"
                color="primary"
                disabled={!files.length >= 1 || !isSuccess}
                onClick={handleSubmit}
              >
                Submit
              </Button> */}
            </div>
          </div>
        </>
      )}
    </section>
  );
}

import { API_HOST } from "./constants";

export default async function callLogout(method, route, body, client) {
  let response;
  if (method === "POST") {
    response = await fetch(`${API_HOST}/${client}/api/${route}`, {
      method,
      headers: { "X-CSRFToken": await getCsrfToken() },
      body,
      credentials: "include",
    });
  } else if (method === "GET") {
    response = await fetch(`${API_HOST}/${client}/api/${route}`, {
      method,
      headers: {},
      credentials: "include",
    });
  }

  const data = await response.json();
  return data;
}

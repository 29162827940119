import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import CircleIcon from "@mui/icons-material/Circle";
import Button from "@mui/material/Button";
import callAPI from "../utils/callAPI";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BOX_SHADOW, NAVBAR_BLUE } from "../utils/constants";
import { useHistory } from "react-router-dom";
import { HOME } from "../utils/constants";
import callLogout from "../utils/callLogout";
const commonPasswords = [
  "password",
  "qwerty123",
  "aa12345678",
  "password1",
  "qwertyuiop",
  "password123",
  "1q2w3e4r",
  "qwertyui",
  "welcome",
  "18atcskd2w",
  "3rjs1la7qe",
];
function ChangePassword({ client }) {
  const history = useHistory();
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confirmPass, setConformPass] = useState("");
  const [body, setBody] = useState({
    old_password: "",
    new_password1: "",
    new_password2: "",
  });
  const [responseMessage, setResponseMessage] = useState("");
  const [responseStatus, setResponseStatus] = useState("");
  const [helperText, setHelperText] = useState("");
  const passLength = (password) => {
    return password.length;
  };
  useEffect(() => {
    setBody({
      old_password: oldPass,
      new_password1: newPass,
      new_password2: confirmPass,
    });
  }, [oldPass, newPass, confirmPass]);
  const [credentials, setCredentials] = useState({
    showOldPassword: false,
    showNewPassword1: false,
    showNewPassword2: false,
  });
  const handleClickShowPassword = (id) => {
    id === "newPass"
      ? setCredentials({
          ...credentials,
          showNewPassword1: !credentials.showNewPassword1,
        })
      : id === "confirmNewPass"
      ? setCredentials({
          ...credentials,
          showNewPassword2: !credentials.showNewPassword2,
        })
      : setCredentials({
          ...credentials,
          showOldPassword: !credentials.showOldPassword,
        });
  };
  const onSubmit = (e) => {
    e.preventDefault();
  };
  const handleLogout = () => {
    callLogout("GET", "logout/", "", client)
      .then(() => {
        document.cookie.split(";").forEach((cookie) => {
          document.cookie = cookie
            .replace(/^ +/, "")
            .replace(/=.*/, `=;expires=${new Date().toUTCString()};path=/`);
        });
      })
      .then(() => {
        window.location.replace(`${HOME}/login`);
      });
  };
  const handleSubmit = () => {
    console.log("onSubmit");
    let len = passLength(body.new_password1);
    let isNum = isNaN(body.new_password1);
    // let isCommon = commonPasswords.indexOf(body.new_password1) >= 0 ? true : false;
    setHelperText("");
    if (len >= 8 && isNum && body.old_password !== body.new_password1) {
      callAPI("POST", "change_password/", JSON.stringify(body), client).then(
        (res) => {
          console.log("onres");
          // setHelperText("");
          setResponseMessage([...res.msg]);
          setResponseStatus(res.status);
          //   setPasswordChanged(true);
          if (res.status === 200) {
            setOldPass("");
            setNewPass("");
            setConformPass("");
            handleLogout();
            // localStorage.removeItem("user");
            // history.push("/login");
          }
        }
      );
    } else if (len < 8) {
      setHelperText("Password must contain 8 characters");
    } else if (!isNum) {
      setHelperText("Password can’t be entirely numeric.");
    } else if (body.old_password === body.new_password1) {
      setHelperText("New password can't be same as old password");
    }
  };
  const oldPassError = responseStatus === "401";
  const newPassError = responseStatus === "406";
  return (
    <div
      className="d-flex flex-column m-auto my-2"
      style={{
        backgroundColor: "white",
        width: "fit-content",
        boxShadow: BOX_SHADOW,
        borderRadius: "10px",
      }}
    >
      <div
        className=" mx-auto mt-2 "
        style={{ display: responseMessage === "Successfull" ? "" : "none" }}
      >
        <h4 style={{ fontWeight: "bolder", color: "rgb(42 169 54)" }}>
          {" "}
          Password Changed Successfully
        </h4>
      </div>
      <form onSubmit={onSubmit}>
        <div
          className="d-flex flex-column mt-5 pb-3 mx-auto change-pass"
          style={{ width: "500px" }}
        >
          <div className="mx-auto mt-2 ">
            <h5 style={{ fontWeight: "bolder", color: "#535454" }}>
              Reset Password
            </h5>
          </div>
          <div className="mx-4 my-4">
            <div className="mx-auto my-2 w-100">
              <TextField
                required
                style={{ borderColor: "white", fontSize: "5px" }}
                id="oldPass"
                error={oldPassError}
                onChange={(event) => setOldPass(event.target.value)}
                value={oldPass}
                label="Old Password"
                variant="outlined"
                type={credentials.showOldPassword ? "text" : "password"}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("oldPass")}
                      >
                        {credentials.showOldPassword ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="mx-auto my-3 w-100">
              <TextField
                required
                helperText={helperText}
                error={newPassError}
                id="newPass"
                onChange={(event) => setNewPass(event.target.value)}
                value={newPass}
                label="New Password"
                variant="outlined"
                type={credentials.showNewPassword1 ? "text" : "password"}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => handleClickShowPassword("newPass")}
                      >
                        {credentials.showNewPassword1 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
            <div className="d-flex ms-3 mt-3 w-100">
              <div className="">
                <CircleIcon style={{ height: "10px" }} />
              </div>
              <div
                // className="ms-3"
                style={{
                  fontSize: "13px",
                  color: "#444",
                  paddingTop: "5px",
                  paddingLeft: "2px",
                }}
              >
                Your password can’t be too similar to your other personal
                information.
              </div>
            </div>
            <div className="d-flex ms-3 w-100 ">
              <div className="">
                <CircleIcon style={{ height: "10px" }} />
              </div>
              <div
                // className="ms-3"
                style={{
                  fontSize: "13px",
                  color: "#444",
                  paddingTop: "5px",
                  paddingLeft: "2px",
                }}
              >
                Your password must contain at least 8 characters.
              </div>
            </div>
            <div className="d-flex ms-3 w-100">
              <div className="">
                <CircleIcon style={{ height: "10px" }} />
              </div>
              <div
                // className="ms-3"
                style={{
                  fontSize: "13px",
                  color: "#444",
                  paddingTop: "5px",
                  paddingLeft: "2px",
                }}
              >
                Your password can’t be a commonly used password.
              </div>
            </div>
            <div className="d-flex ms-3 w-100">
              <div className="">
                <CircleIcon style={{ height: "10px" }} />
              </div>
              <div
                // className="ms-3"
                style={{
                  fontSize: "13px",
                  color: "#444",
                  paddingTop: "5px",
                  paddingLeft: "2px",
                }}
              >
                Your password can’t be entirely numeric.
              </div>
            </div>
            <div className="mx-auto mt-3 mb-3 w-100 ">
              <div
                className="d-flex mb-4 w-100 justify-content-center"
                style={{
                  flexDirection: "column",
                }}
              >
                {helperText !== "" ? (
                  <div
                    className="ms-3"
                    style={{ fontSize: "13px", color: "red" }}
                  >
                    {helperText}
                  </div>
                ) : responseMessage === "Successfull" ? (
                  ""
                ) : (
                  responseMessage &&
                  responseMessage.map((msg) => {
                    console.log("msg:", msg);
                    return (
                      <div
                        className="ms-3"
                        style={{ fontSize: "13px", color: "red" }}
                      >
                        {msg}
                      </div>
                    );
                  })
                )}
              </div>
              <TextField
                required
                error={newPassError}
                id="confirmNewPass"
                value={confirmPass}
                onChange={(event) => setConformPass(event.target.value)}
                label="Confirm New Password"
                variant="outlined"
                type={credentials.showNewPassword2 ? "text" : "password"}
                fullWidth
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          handleClickShowPassword("confirmNewPass")
                        }
                      >
                        {credentials.showNewPassword2 ? (
                          <VisibilityIcon />
                        ) : (
                          <VisibilityOffIcon />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          </div>
          <Button
            className="mx-4"
            type="submit"
            variant="contained"
            style={{
              backgroundColor: "#305697",
              opacity: "0.9",
              color: "white",
            }}
            onClick={() => handleSubmit()}
          >
            Change my password
          </Button>
        </div>
      </form>
    </div>
  );
}
export default ChangePassword;

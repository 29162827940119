import * as React from "react";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { NAVBAR_BLUE } from "../../utils/constants";
import { CircularProgress } from "@mui/material";

export default function BasicPopover({
  files,
  isSuccess,
  handleSubmit,
  isFileProcessing,
  handlePopOverClose,
  anchorEl,
  popOverRef,
  submitButtonLoading,
}) {
  const open = Boolean(anchorEl);

  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        disabled={!files.length >= 1 || !isSuccess}
        variant="contained"
        color="primary"
        ref={popOverRef}
        onClick={handleSubmit}
      >
        {submitButtonLoading ? (
          <CircularProgress size="30px" style={{ color: "white" }} />
        ) : (
          "Submit"
        )}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopOverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 2 }}>
          Previous file is under process, please try again after some time.
        </Typography>
      </Popover>
    </div>
  );
}

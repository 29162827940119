import React, { useState, useEffect } from "react";
// import "./side.css";
import { styled } from "@mui/material/styles";
import { NavLink, useLocation } from "react-router-dom";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import SummarizeIcon from "@mui/icons-material/Summarize";
import AppsIcon from "@mui/icons-material/Apps";
import logo from "url:../../assets/images/sherlock_logo.png";

import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import IconButton from "@mui/material/IconButton";
import LockResetIcon from "@mui/icons-material/LockReset";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import callAPI from "../../utils/callAPI";
const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  minHeight: "10px",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});
const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const ModifiedListItemIcon = styled(ListItemIcon)(() => ({ minWidth: "20px" }));
const CollapsedListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: "max-content",
}));

export default function Side({ isClicked, setIsClicked, client }) {
  const [colr, setColr] = useState("#ccddff");
  const [btncolr, setBtncolr] = useState("#007ade");
  const [accessSet, setAccessSet] = useState();

  const [open, setOpen] = React.useState(false);
  const [hide, setHide] = useState("block");

  const location = useLocation();
  const pathName = location.pathname;
  const [isDashboardClicked, setIsDashboardClicked] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const handleDashboardClick = () => {
    handleDrawerOpen();
    setIsDashboardClicked(true);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
    setIsClicked(true);
    setHide("none");
  };

  const handleDrawerClose = () => {
    setOpen(false);
    setIsClicked(false);
    setHide("block");
  };
  useEffect(() => {
    if (!open) {
      setIsDashboardClicked(false);
    }
  }, [open]);
  useEffect(() => {
    callAPI("GET", "user_permissions", "", client).then((res) => {
      setAccessSet(res.permissions);
    });
  }, []);

  return (
    <Drawer variant="permanent" open={open} onClose={handleDrawerClose}>
      <div
        className="d-flex link m-0 mb-5 d-flex align-items-center justify-content-center"
        style={{ maxHeight: "64px", height: "64px" }}
      >
        <button
          style={{
            backgroundColor: "white",

            border: "none",
            marginTop: "10px",
            height: "35px",
            width: "60px",
            fontSize: "20px",
            display: hide,
          }}
          onClick={handleDrawerOpen}
          onMouseEnter={() => {
            setColr("#007ade");
            setBtncolr("white");
          }}
          onMouseLeave={() => {
            setColr("#ccddff");
            setBtncolr("#007ade");
          }}
        >
          <AppsIcon
            style={{ fontSize: 30, marginTop: "-10px", color: "#305697" }}
          />
        </button>
        {open ? (
          <>
            <img src={logo} />
            {/* <span className="ms-2">Sherlock AI</span> */}
            <span className="ms-2">Sherlock AI</span>
            <DrawerHeader style={{ minHeight: "20px" }}>
              <IconButton onClick={handleDrawerClose}>
                <ChevronLeftIcon />
              </IconButton>
            </DrawerHeader>
          </>
        ) : null}
      </div>
      {open ? (
        <div
          style={{
            marginTop: "-20px",
          }}
        >
          <List
            className="ps-4"
            style={{
              display: "flex",
              flexDirection: "column",
              // gap: "0.9rem",
              marginLeft: "8px",
            }}
          >
            <NavLink
              to="/uploadexcel"
              style={{
                display:
                  accessSet && accessSet.indexOf("uploadexcel") > -1
                    ? ""
                    : "none",
              }}
            >
              <ListItem button>
                <ModifiedListItemIcon>
                  <CloudUploadIcon
                    style={{
                      fontSize: "25px",
                      color: pathName === "/uploadexcel" ? "#305697" : "",
                      // paddingTop: "8px",
                      // paddingBottom: "8px",
                      // paddingLeft: "16px",
                      // paddingRight: "16px",
                    }}
                  />
                </ModifiedListItemIcon>

                <ListItemText
                  disableTypography
                  className="ms-2"
                  primary={
                    <Typography variant="body2" style={{ fontSize: "15px" }}>
                      Upload Claims
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>
            <NavLink
              to="/downloadexcel"
              style={{
                display:
                  accessSet && accessSet.indexOf("downloadexcel") > -1
                    ? ""
                    : "none",
              }}
            >
              <ListItem button>
                <ModifiedListItemIcon>
                  <CloudDownloadIcon
                    style={{
                      fontSize: "25px",
                      color: pathName === "/downloadexcel" ? "#305697" : "",
                    }}
                  />
                </ModifiedListItemIcon>

                <ListItemText
                  disableTypography
                  className="ms-2"
                  primary={
                    <Typography variant="body2" style={{ fontSize: "15px" }}>
                      Download Claims
                    </Typography>
                  }
                />
              </ListItem>
            </NavLink>

            <NavLink
              to="/throughput_report"
              style={{
                display:
                  accessSet && accessSet.indexOf("throughput_report") > -1
                    ? ""
                    : "none",
              }}
            >
              <ListItem button>
                <ModifiedListItemIcon>
                  <RecentActorsIcon
                    style={{
                      fontSize: "25px",
                      color: pathName === "/throughput_report" ? "#305697" : "",
                    }}
                  />
                </ModifiedListItemIcon>

                <ListItemText
                  disableTypography
                  className="ms-2"
                  primary={
                    <Typography variant="body2" style={{ fontSize: "15px" }}>
                      Throughput Report
                    </Typography>
                  }
                />
              </ListItem>

              <NavLink
                to="/exceltemplate"
                style={{
                  display:
                    accessSet && accessSet.indexOf("exceltemplate") > -1
                      ? ""
                      : "none",
                }}
              >
                <ListItem button>
                  <ModifiedListItemIcon>
                    <SummarizeIcon
                      style={{
                        fontSize: "25px",
                        color: pathName === "/exceltemplate" ? "#305697" : "",
                      }}
                    />
                  </ModifiedListItemIcon>
                  <ListItemText
                    disableTypography
                    className="ms-2"
                    primary={
                      <Typography variant="body2" style={{ fontSize: "15px" }}>
                        Claims Template
                      </Typography>
                    }
                  />
                </ListItem>
              </NavLink>
            </NavLink>
          </List>

          {/* <img
            src={logo1}
            height="70px"
            width="180px"
            style={{ position: "fixed", bottom: 13, marginLeft: "24px" }}
          /> */}
        </div>
      ) : (
        <List
          style={{
            marginTop: "-20px",
            // display: "flex",
            // flexDirection: "column",
            // gap: "0.9rem",
            // marginLeft: "8px",
          }}
        >
          <NavLink
            to="/uploadexcel"
            style={{
              display:
                accessSet && accessSet.indexOf("uploadexcel") > -1
                  ? ""
                  : "none",
            }}
          >
            <ListItem className="d-flex justify-content-center mb-2" button>
              <CollapsedListItemIcon>
                <CloudUploadIcon
                  style={{
                    color: pathName === "/uploadexcel" ? "#305697" : "",
                  }}
                />
              </CollapsedListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/downloadexcel"
            style={{
              display:
                accessSet && accessSet.indexOf("downloadexcel") > -1
                  ? ""
                  : "none",
            }}
          >
            <ListItem className="d-flex justify-content-center mb-2" button>
              <CollapsedListItemIcon>
                <CloudDownloadIcon
                  style={{
                    color: pathName === "/downloadexcel" ? "#305697" : "",
                  }}
                />
              </CollapsedListItemIcon>
            </ListItem>
          </NavLink>
          <NavLink
            to="/throughput_report"
            style={{
              display:
                accessSet && accessSet.indexOf("throughput_report") > -1
                  ? ""
                  : "none",
            }}
          >
            <ListItem className="d-flex justify-content-center mb-2" button>
              <CollapsedListItemIcon>
                <RecentActorsIcon
                  style={{
                    color: pathName === "/throughput_report" ? "#305697" : "",
                  }}
                />
              </CollapsedListItemIcon>
            </ListItem>
          </NavLink>

          <NavLink
            to="/exceltemplate"
            style={{
              display:
                accessSet && accessSet.indexOf("exceltemplate") > -1
                  ? ""
                  : "none",
            }}
          >
            <ListItem className="d-flex justify-content-center mb-2" button>
              <CollapsedListItemIcon>
                <SummarizeIcon
                  style={{
                    color: pathName === "/exceltemplate" ? "#305697" : "",
                  }}
                />
              </CollapsedListItemIcon>
            </ListItem>
          </NavLink>
        </List>
      )}
    </Drawer>
  );
}

// export default Navbar;

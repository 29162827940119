import { Button, LinearProgress } from "@mui/material";
import React, { useState, useRef, useMemo, useEffect } from "react";
import Table from "../components/table/Table";
import useAsyncError from "../customHooks/useAsyncError";
import callAPI from "../utils/callAPI";
import { useHistory } from "react-router-dom";
import NoAccess from "./NoAccess";
import { GREEN } from "../utils/constants";

function DocumentExcel({ isClicked, client }) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const currentTable = useRef(null);
  const throwError = useAsyncError();
  const [data, setData] = useState({});
  const [accessSet, setAccessSet] = useState();
  let tableData;

  useEffect(() => {
    if (accessSet && accessSet.indexOf("exceltemplate") > -1) {
      setIsLoading(true);
      callAPI("GET", `get_template`, "", client)
        .then((res) => {
          setData({ ...res });
          setIsLoading(false);
        })
        .catch((error) => {
          setIsLoading(false);
          throwError(new Error(error));
        });
    } else if (accessSet) {
      history.push(NoAccess);
    }
  }, [accessSet, client]);

  useEffect(() => {
    setIsLoading(true);
    callAPI("GET", "user_permissions", "", client).then((res) => {
      setAccessSet(res.permissions);
      setIsLoading(false);
    });
    setIsLoading(false);
  }, [client]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: "#",
        accessor: "sNo",
      },
      {
        Header: "Title",
        accessor: "title",
      },
      {
        Header: "Description",
        accessor: "desc",
      },
    ],
    [history]
  );

  if (data && Object.keys(data).length) {
    tableData = [];
    for (let i = 0; i < Object.keys(data).length; i += 1) {
      tableData.push({
        sNo: i + 1,
        title: data[i]["title"],
        desc: data[i]["desc"],
      });
    }
  }

  return (
    <>
      {isLoading ? (
        <LinearProgress
          style={{ color: "rgb(0, 122, 222)", marginTop: "", opacity: "0.8" }}
        />
      ) : (
        <div
          className="throughputReport mx-4 my-2 "
          style={{ transitionDuration: "0.5s" }}
          // style={{ marginLeft: isClicked ? "30vw" : "" }}
        >
          <div
            style={{
              marginLeft: isClicked ? "155px" : "",
              transitionDuration: "0.5s",
            }}
          >
            <Button
              style={{
                margin: "70px 0 -50px",

                backgroundColor: "#305697",
              }}
              variant="contained"
              onClick={() => {
                window.location.href =
                  "http://pilot.perceptiviti.com/pilot/files/sample/Sample_Excel.xlsx";
              }}
            >
              Download Sample Excel
            </Button>
          </div>
          <div
            style={{
              marginLeft: isClicked ? "100px" : "-55px",
              marginRight: "55px",
              transitionDuration: "0.5s",
            }}
          >
            {data && Object.keys(data).length ? (
              <Table columns={COLUMNS} data={tableData} ref={currentTable} />
            ) : (
              ""
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default DocumentExcel;

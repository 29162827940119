import React, { Suspense, useState, useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";

import ProtectedRoute from "./HOC/ProtectedRoute";
import Home from "./routes/Home";
import History from "./routes/History";
import ChangePassword from "./routes/ChangePassword";

import Login from "./routes/Login";

import ThroughputReport from "./routes/ThroughputReport";
import DocumentExcel from "./routes/DocumentExcel";
import NoAccess from "./routes/NoAccess";

import { useLocation } from "react-router-dom";
import callAPI from "./utils/callAPI";
import {
  CLIENT_OPTION_VISIBILITY,
  SERVER_NAME,
  clientOptions,
} from "./utils/constants";

// Rendered when page is loading
const renderLoader = () => (
  <>
    <main className="d-flex justify-content-center">
      <CircularProgress />
    </main>
  </>
);
export default function Router() {
  const { pathname } = useLocation();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessSet, setAccessSet] = useState();
  const [redirectTo, setRedirectTo] = useState();
  const dropdownClient = Object.keys(clientOptions[SERVER_NAME])[0];
  // const dropdownClient =
  //   CLIENT_OPTION_VISIBILITY === "No" ? SERVER_NAME : "watania";
  const [client, setClient] = useState(dropdownClient);
  const [passwordChanged, setPasswordChanged] = useState(false);

  //   callAPI("GET", "login/", "")
  //   .then((res) => {
  //     if(res.is_authenticated){
  //       setIsAuthenticated(true);
  //   }else{
  //     setIsAuthenticated(null);
  //   }
  // });

  useEffect(() => {
    accessSet && accessSet.length && setRedirectTo(accessSet[0]);
  }, [accessSet]);

  useEffect(() => {
    callAPI("GET", "user_permissions", "", client).then((res) => {
      setAccessSet(res.permissions);
    });
  }, []);

  return (
    <>
      {/* <CustomNavbar /> */}
      {redirectTo ? (
        <main>
          <Suspense fallback={renderLoader()}>
            <Switch>
              <Route exact path="/login">
                <Login client={client} setClient={setClient} />
              </Route>
              <ProtectedRoute
                exact
                path="/uploadexcel"
                component={Home}
                client={client}
                setClient={setClient}
              />
              <ProtectedRoute
                exact
                path="/downloadexcel"
                component={History}
                client={client}
                setClient={setClient}
              />
              <ProtectedRoute
                exact
                path="/noaccess"
                component={NoAccess}
                client={client}
                setClient={setClient}
              />
              <ProtectedRoute
                exact
                path="/exceltemplate"
                component={DocumentExcel}
                client={client}
                setClient={setClient}
              />
              <ProtectedRoute
                exact
                path="/throughput_report"
                component={ThroughputReport}
                isAuthenticated={isAuthenticated}
                client={client}
                setClient={setClient}
              />
              <ProtectedRoute
                exact
                path="/change_password"
                client={client}
                setClient={setClient}
                component={ChangePassword}
                isAuthenticated={isAuthenticated}
                // passwordChanged={passwordChanged}
                // setPasswordChanged={setPasswordChanged}
              />
              <Redirect exact from="/" to={`/${redirectTo}`} />

              {/* <ProtectedRoute 
              path="/throughput_report/:month/:year" 
              component={ThroughputReport} 
              isAuthenticated={isAuthenticated}
              
           /> */}
            </Switch>
          </Suspense>
        </main>
      ) : (
        ""
      )}
    </>
  );
}

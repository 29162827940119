import React, { useEffect, useState } from "react";
import { Route, Redirect, useHistory, useLocation } from "react-router-dom";
import { useAuth } from "../context/authContext";
import CustomNavbar from "../components/navbar/Navbar";
import callAPI from "../utils/callAPI";

export default function ProtectedRoute({
  component: Component,
  isAuthenticated,
  client,
  setClient,
  ...rest
}) {
  const currentDate = new Date();

  const auth = useAuth();
  const history = useHistory();
  const loggedInUser = localStorage.getItem("user");
  const { pathname } = useLocation();
  const [selectedMonth, setSelectedMonth] = useState(
    currentDate.getMonth() + 1
  );
  const [isClicked, setIsClicked] = useState(false);
  const [currentYear, setCurrentYear] = useState(currentDate.getFullYear());

  useEffect(() => {
    callAPI("GET", "logged_in/", "", client).then((res) => {
      if (!res.is_authenticated) {
        localStorage.removeItem("user");
        history.push("/login");
      } else {
        const userName = res.user;
        if (res.status === 200) {
          auth.signin(200, userName);
        } else {
          auth.signin(400, userName);
        }
      }
    });
  }, [history]);

  return (
    <>
      {pathname !== "/noaccess" ? (
        <CustomNavbar
          selectedMonth={selectedMonth}
          setSelectedMonth={setSelectedMonth}
          isClicked={isClicked}
          setIsClicked={setIsClicked}
          client={client}
          setClient={setClient}
          currentYear={currentYear}
          setCurrentYear={setCurrentYear}
        />
      ) : (
        ""
      )}

      <Route
        {...rest}
        render={({ match, location }) =>
          loggedInUser !== (undefined || null) ? (
            <main>
              <Component
                match={match}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                isClicked={isClicked}
                client={client}
                setClient={setClient}
                currentYear={currentYear}
                setCurrentYear={setCurrentYear}
              />
            </main>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: location },
              }}
            />
          )
        }
      />
    </>
  );
}

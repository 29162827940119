export default function changeDateFormat(originalDate) {
  const dateReceived = new Date(originalDate);
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ][dateReceived.getMonth()];
  const dateString = `${dateReceived.getDate()} ${month} ${dateReceived.getFullYear()}`;
  return dateString;
}

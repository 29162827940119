import React, { useEffect, useRef, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import { useHistory } from "react-router-dom";

import CircularProgress from "@mui/material/CircularProgress";

import "../sass/index.sass";
import { NAVBAR_BLUE } from "../utils/constants";
import { SERVER_NAME } from "../utils/constants";
import Table from "../components/table/Table";

import useAsyncError from "../customHooks/useAsyncError";
import callAPI from "../utils/callAPI";
import { DJANGO_APP_HOME } from "../utils/constants";
import changeDateFormat from "../utils/changeDateFormat";
import { NoAccess } from "../utils/constants";
export default function History({ isClicked, client }) {
  const throwError = useAsyncError();
  const [isLoading, setIsLoading] = useState(true);
  const [accessSet, setAccessSet] = useState();

  const [data, setData] = useState({
    documents: [],
  });

  const currentTable = useRef(null);
  const history = useHistory();
  let tableData;

  useEffect(() => {
    if (accessSet && accessSet.indexOf("downloadexcel") > -1) {
      callAPI("GET", "download_input/", "", client)
        .then((res) => {
          const sortedArray = res.file;
          sortedArray.sort(function (firstElement, secondElement) {
            const firstDate = new Date(firstElement.uploaddate);
            const secondDate = new Date(secondElement.uploaddate);
            // Compare the 2 dates
            if (firstDate > secondDate) return -1;
            if (firstDate < secondDate) return 1;
            return 0;
          });

          const documents = sortedArray.map((document) => {
            const inputDocument = [...document.input];
            const uploadDate = changeDateFormat(document.uploaddate);
            let outputDocument = [];
            let outputFiles = [];
            let inputFiles = [];
            if (
              "output" in document &&
              document.input.length > document.output.length
            ) {
              document.output.map((output) => {
                outputFiles = [...outputFiles, output.file_name];
              });
              document.input.map((input) => {
                if (
                  !outputFiles.includes(
                    input.file_name.replace("live-", "output-")
                  )
                ) {
                  inputFiles = [...inputFiles, input];
                }
              });
              outputDocument = [...document.output, ...inputFiles];
            } else if ("output" in document) {
              outputDocument = [...document.output];
            } else {
              outputDocument = [...document.input];
            }
            return { inputDocument, uploadDate, outputDocument };
          });
          setData({ documents: [...documents] });
          setIsLoading(false);
        })
        .catch((error) => {
          throwError(new Error(error));
        });
    } else if (accessSet) {
      history.push(NoAccess);
    }
  }, [throwError, accessSet, client]);

  useEffect(() => {
    setIsLoading(true);
    callAPI("GET", "user_permissions", "", client).then((res) => {
      setAccessSet(res.permissions);
      // setIsLoading(false);
    });
    // setIsLoading(false);
  }, [client]);

  const navigateToHome = () => {
    history.push("/");
  };

  const saveFile = (fileName, filePath) => {
    saveAs(`${DJANGO_APP_HOME}/${SERVER_NAME}${filePath}`, fileName);
  };

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "sNo", // accessor is the "key" in the data
      },
      {
        Header: "Upload Date",
        accessor: "uploadDate", // accessor is the "key" in the data
      },
      {
        Header: "Input Documents",
        accessor: "inputDocuments",
        Cell: function inputDocuments({ cell }) {
          const inputDocumentList = cell.row.values.inputDocuments;
          return (
            <div
              className="text-start text-break"
              style={{ color: NAVBAR_BLUE }}
            >
              {inputDocumentList.map((inputDocument, currentIndex, arr) => {
                let isLast = false;
                if (currentIndex + 1 === arr.length) {
                  isLast = true;
                } else {
                  isLast = false;
                }
                return (
                  <a
                    href={`${DJANGO_APP_HOME}/${client}${inputDocument.file_path}`}
                    style={{ cursor: "pointer" }}
                    key={`${inputDocument.file_path}`}
                  >
                    {inputDocument.file_name}
                    {isLast ? "" : <>&#44;</>}{" "}
                  </a>
                );
              })}
            </div>
          );
        },
      },
      {
        Header: "Output Documents",
        accessor: "outputDocument",
        Cell: function validateButton({ cell }) {
          const outputDocumentList = cell.row.values.outputDocument;
          return (
            <div className="text-start text-break">
              {outputDocumentList.map((outputDocument, currentIndex, arr) => {
                let isLast = false;
                if (currentIndex + 1 === arr.length) {
                  isLast = true;
                } else {
                  isLast = false;
                }
                // file_name = outputDocument.file_name.replace("live-", "");
                if (outputDocument.file_type === "HISTORIC") {
                  return (
                    <span>
                      <span>{outputDocument.file_name} submitted</span>
                    </span>
                  );
                } else if (
                  outputDocument.processed &&
                  outputDocument.file_name.includes("live") === false
                ) {
                  return (
                    <a
                      href={`${DJANGO_APP_HOME}/${client}${outputDocument.file_path}`}
                      style={{ cursor: "pointer", color: NAVBAR_BLUE }}
                      key={`${outputDocument.file_path}`}
                    >
                      {outputDocument.file_name}
                      {isLast ? "" : <>&#44;</>}{" "}
                    </a>
                  );
                } else if (outputDocument.submitted) {
                  return (
                    <span>
                      Output for "{outputDocument.file_name}" file will be
                      available 2-3 hours after submitting the claims.{" "}
                    </span>
                  );
                } else if (!outputDocument.submitted) {
                  return (
                    <span>
                      Please upload and submit "{outputDocument.file_name}" file
                      again.{" "}
                    </span>
                  );
                } else {
                  <span>File submitted </span>;
                }
              })}
            </div>
          );
        },
      },
    ],
    [history]
  );

  if (data.documents.length) {
    tableData = [];
    for (let i = 0; i < data.documents.length; i += 1) {
      tableData.push({
        sNo: i + 1,
        inputDocuments: data.documents[i].inputDocument,
        uploadDate: data.documents[i].uploadDate,
        outputDocument: data.documents[i].outputDocument,
      });
    }
  }
  return (
    <section className="history">
      {isLoading ? (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ height: "50vh", paddingLeft: "6rem" }}
        >
          <CircularProgress size="60px" style={{ color: "#305697" }} />
        </div>
      ) : (
        <>
          <div
            className="mt-5"
            style={{
              width: isClicked ? "74vw" : "",
              marginLeft: isClicked ? "200px" : "",
              transitionDuration: "0.5s",
            }}
          >
            {data.documents.length ? (
              <Table columns={columns} data={tableData} ref={currentTable} />
            ) : (
              ""
            )}
          </div>
        </>
      )}
    </section>
  );
}

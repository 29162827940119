import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { SERVER_NAME, clientOptions } from "../../utils/constants";
export default function SetClient({ client, setClient }) {
  const handleChange = (event) => {
    console.log(event.target.value);
    setClient(event.target.value);
  };

  return (
    <FormControl
      style={{ m: 1, minWidth: 150 }}
      size="small"
      className="setClient"
    >
      <InputLabel id="demo-select-small" style={{ color: "#305697" }}>
        Business Book
      </InputLabel>
      <Select
        labelId="demo-select-small"
        id="demo-select-small"
        value={client}
        label="Client"
        onChange={handleChange}
        style={{
          color: "#305697",
          borderRadius: "21px",
          backgroundColor: "#efefef87",
        }}
      >
        {Object.keys(clientOptions[SERVER_NAME]).map((clientKey) => {
          return (
            <MenuItem value={clientKey}>
              {clientOptions[SERVER_NAME][clientKey]}
            </MenuItem>
          );
        })}
        {/* <MenuItem value={"watania"} style={{ color: "#305697" }}>
          Watania
        </MenuItem>
        <MenuItem value={"noor"}>Noor</MenuItem>
        <MenuItem value={"ntf"}>NTF</MenuItem> */}
      </Select>
    </FormControl>
  );
}

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useRef, useMemo } from "react";
import { useState } from "react";
import callAPI from "../utils/callAPI";
import useAsyncError from "../customHooks/useAsyncError";
import { LinearProgress } from "@mui/material";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import Table from "../components/table/Table";
import changeDateFormat from "../utils/changeDateToMMDDYYYY";
import { NoAccess } from "../utils/constants";
import TagIcon from "@mui/icons-material/Tag";
import dirham from "../assets/images/dirham.jpg";

function ThroughputReport({ selectedMonth, isClicked, client, currentYear }) {
  const currentDate = new Date();
  const currentTable = useRef(null);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);

  const [data, setData] = useState({
    msg: "",
    data: {
      title: "",
      days: [],
      total: "",
      amount: "",
      report: "",
      help: "",
      months: {},
      current_year: "",
    },
  });

  const throwError = useAsyncError();
  const [accessSet, setAccessSet] = useState();

  let tableData;

  useEffect(() => {
    if (accessSet && accessSet.indexOf("throughput_report") > -1) {
      setIsLoading(true);
      if (selectedMonth && currentYear) {
        callAPI(
          "GET",
          `throughput_report/${selectedMonth}/${currentYear}`,
          "",
          client
        )
          .then((res) => {
            setData({ ...res });
            setIsLoading(false);
          })
          .catch((error) => {
            setIsLoading(false);
            throwError(new Error(error));
          });
      }
    } else if (accessSet) {
      history.push(NoAccess);
    }
  }, [throwError, selectedMonth, currentYear, accessSet, client]);

  useEffect(() => {
    setIsLoading(true);
    callAPI("GET", "user_permissions", "", client).then((res) => {
      setAccessSet(res.permissions);
      setIsLoading(false);
    });
    setIsLoading(false);
  }, [client]);

  const COLUMNS = useMemo(
    () => [
      {
        Header: "#",
        accessor: "sNo",
      },
      {
        Header: "Sherlock Date",
        accessor: "sherlockDate",
      },
      {
        Header: "File Name",
        accessor: "fileName",
      },
      {
        Header: "Claim Count",
        accessor: "claimCount",
      },
      {
        Header: "Claim Amount",
        accessor: "claimAmount",
      },
    ],
    [history]
  );

  if (data.data.days.length) {
    tableData = [];
    for (let i = 0; i < data.data.days.length; i += 1) {
      tableData.push({
        sNo: i + 1,
        sherlockDate: changeDateFormat(data.data.days[i][0]),
        fileName: data.data.days[i][1],
        claimCount: data.data.days[i][2],
        claimAmount: parseFloat(
          parseFloat(data.data.days[i][3]).toFixed(2)
        ).toLocaleString(),
      });
    }
  }

  return (
    <>
      {isLoading ? (
        <LinearProgress
          style={{
            color: "rgb(0, 122, 222)",
            marginTop: "",
            marginLeft: "-90px",
            opacity: "0.8",
          }}
        />
      ) : data.data.days.length ? (
        <div
          className="throughputReport"
          style={{
            width: isClicked ? "85vw" : "",
            float: isClicked ? "right" : "",
            transitionDuration: "0.5s",
          }}
        >
          <div
            className="d-flex justify-content-between align-items-center"
            style={{
              margin: "auto",
              width: isClicked ? "79vw" : "84vw",
              transitionDuration: "0.5s",
            }}
          >
            <div
              className="mx-2"
              style={{
                boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
                padding: "12px 38px",
                borderRadius: "10px",
                // maxWidth: "37vw",
                flexBasis: "100%",
                // width: "550px",
                height: "70px",
                // marginLeft: isClicked ? "212px" : "67px",
                backgroundColor: "#ffff",
                transitionDuration: "0.5s",
              }}
            >
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  Claims
                  <br />
                  <b>{data.data.total.toLocaleString("en-IN")}</b>
                </div>

                <div
                // style={{ marginLeft: "450px", marginTop: "-49px" }}
                >
                  <TagIcon sx={{ fontSize: "50px", color: "#008000" }} />
                </div>
              </div>
            </div>
            <div
              className="mx-2"
              style={{
                boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
                padding: "12px 38px",
                borderRadius: "10px",
                flexBasis: "100%",
                height: "70px",
                marginLeft: "3rem",
                marginRight: "1rem",
                backgroundColor: "#ffff",
                transitionDuration: "0.5s",
              }}
            >
              <div
                style={{ width: "100%" }}
                className="d-flex justify-content-between align-items-center"
              >
                <div>
                  Amount
                  <br />
                  <b>
                    AED{" "}
                    {data.data.amount === ""
                      ? ""
                      : parseFloat(
                          parseFloat(data.data.amount).toFixed(2)
                        ).toLocaleString()}
                  </b>
                </div>

                <div>
                  <img src={dirham} height={50} width={50} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="mt-3 d-flex justify-content-center align-items-center"
            style={{
              marginRight: "7rem",
              transitionDuration: "0.5s",
            }}
          >
            <div style={{ width: "83vw", transitionDuration: "0.5s" }}>
              {data.data.days.length ? (
                <Table columns={COLUMNS} data={tableData} ref={currentTable} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex" style={{ marginTop: "5vw" }}>
            <div
              style={{
                boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
                padding: "12px 38px",
                borderRadius: "10px",
                width: "550px",
                height: "70px",
                marginLeft: isClicked ? "212px" : "67px",
                backgroundColor: "#ffff",
                transitionDuration: "0.5s",
              }}
            >
              <div style={{ width: "100%" }}>
                <div>Claims</div>
                <div>
                  <b>{data.data.total.toLocaleString("en-IN")}</b>
                </div>
                <div
                  className="d-flex"
                  style={{ marginLeft: "450px", marginTop: "-49px" }}
                >
                  <TagIcon sx={{ fontSize: "50px", color: "#008000" }} />
                </div>
              </div>
            </div>
            <div
              style={{
                boxShadow: "rgb(99 99 99 / 20%) 0px 2px 8px 0px",
                padding: "12px 38px",
                borderRadius: "10px",
                width: isClicked ? "479px" : "570px",
                height: "70px",
                marginLeft: isClicked ? "34px" : "83px",
                backgroundColor: "#ffff",
                transitionDuration: "0.5s",
              }}
            >
              <div style={{ width: "100%" }}>
                <div>Amount</div>
                <div>
                  <b>
                    AED{" "}
                    {data.data.amount === ""
                      ? ""
                      : parseFloat(
                          parseFloat(data.data.amount).toFixed(2)
                        ).toLocaleString()}
                  </b>
                </div>

                <div
                  className="d-flex"
                  style={{
                    marginLeft: isClicked ? "376px" : "465px",
                    marginTop: "-49px",
                    height: "60px",
                    width: "30px",
                    transitionDuration: "0.5s",
                  }}
                >
                  <img src={dirham} />
                </div>
              </div>
            </div>
          </div>
          <div
            className="my-5 d-flex justify-content-center align-items-center"
            style={{ margin: "0 auto", color: "grey" }}
          >
            <WarningRoundedIcon
              className="mx-2"
              style={{ height: "30px", width: "30px", color: "grey" }}
            />{" "}
            No Data Available!!
          </div>
        </div>
      )}
    </>
  );
}

export default ThroughputReport;
